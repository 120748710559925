import { required, email, max, min, confirmed, integer, numeric, min_value } from 'vee-validate/dist/rules'
import { extend, setInteractionMode } from 'vee-validate'
import i18n from '@/plugins/i18n.js'
setInteractionMode('eager')

extend('required', {
  ...required,
  message: i18n.t('inputRules.requiredField')
})

extend('max', {
  ...max,
  message: 'This may not be greater than field characters'
})

extend('email', {
  ...email,
  message: i18n.t('inputRules.validEmail')
})

extend('minPhone', {
  ...min,
  message: i18n.t('inputRules.number')
})

extend('maxPhone', {
  ...max,
  message: i18n.t('inputRules.number')
})

extend('minCoupon', {
  ...min,
  message: i18n.t('inputRules.coupon')
})

extend('maxCoupon', {
  ...max,
  message: i18n.t('inputRules.coupon')
})

extend('maxPhone', {
  ...max,
  message: i18n.t('inputRules.number')
})

extend('maxDesc', {
  ...max,
  message: i18n.t('inputRules.maxCharacter')
})

extend('minPass', {
  ...min,
  message: i18n.t('inputRules.min_pass_6')
})

extend('confirmed', {
  ...confirmed,
  message: i18n.t('inputRules.pass_not_match')
})

extend('integer',  {
  ...integer,
  message: i18n.t('inputRules.numeric')
})

extend('numeric',  {
  ...numeric,
  message: i18n.t('inputRules.numeric')
})

extend('min_value',  {
  ...min_value,
  message: i18n.t('inputRules.minValue') + ' {min}' ,
})
